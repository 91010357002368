<template>
    <div
        class="page-index"
    >
        <HeroCarousel
            :css-height-value="carouselMobileHeight"
        ></HeroCarousel>

        <section v-if="true" v-once class="featured-items overflow-hidden">
            <FeaturedProducts
                v-once
                :item-aspect-ratio="featItemAspectRatio"
            ></FeaturedProducts>

            <!-- Videos -->
            <article v-once class="pa-6 section-border-y">
                <v-row no-gutters>
                    <v-col>
                        <h2 class="text-center font-weight-bold text-uppercase font-size-5 letter-spacing-5">Latest Videos</h2>
                    </v-col>
                </v-row>
                <ContainerContent>
                    <v-row>
                        <v-col cols="12" md="4" v-for="(item, index) in FeaturedVideosList" :key="index">
                            <VideoDialog
                                v-once
                                :video-id="item.youtubeId"
                            >
                                <template #default="{ props }">
                                    <v-card
                                        elevation="4"
                                        class="card-play-video"
                                        v-bind="props"
                                        role="button"
                                        :aria-label="item.title"
                                    >
                                        <v-img :src="getResourceCdnUrl(item.thumbnail)" :aspect-ratio="featItemAspectRatio" cover></v-img>
                                        <v-icon class="card-play-video-icon" size="80" :icon="mdiPlayCircle"></v-icon>
                                    </v-card>
                                    <div class="d-block text-center text-uppercase font-size-7 letter-spacing-5 font-weight-medium mt-4">
                                        {{ item.title }}
                                    </div>
                                </template>
                            </VideoDialog>
                        </v-col>
                    </v-row>
                </ContainerContent>
            </article>

            <article class="pa-6 section-border-y">
                <v-row dense no-gutters>
                    <v-col cols="12" md="6" class="pb-8">
                        <h3 class="text-center text-uppercase font-size-6 letter-spacing-5 mb-6">
                            Official Lacrosse Equipment Supplier
                        </h3>
                        <FeaturedPartnerBadge
                            v-once
                            class="d-flex flex-shrink-1 flex-grow-0 flex-column justify-center"
                        ></FeaturedPartnerBadge>
                    </v-col>
                    <v-col cols="12" md="6">
                        <h3 class="text-center text-uppercase font-size-6 letter-spacing-5">
                            Product Announcements &amp; Promotions
                        </h3>
                        <div class="newsletter-box d-flex flex-column justify-center">
                            <NewsletterSignup></NewsletterSignup>
                        </div>
                    </v-col>
                </v-row>
            </article>
        </section>
    </div>
</template>

<script lang="ts" setup>
    import {
        useCdnResource,
        getMobileHeightValue,
        useBrowserInfo,
        useGlobalPageBanner,
        useUiState
    } from '~/composables';
    import { FeaturedVideosList } from '~/models';
    import { mdiPlayCircle } from '@mdi/js';
    import ContainerContent from '~/components/ContainerContent/ContainerContent.vue';


    const HeroCarousel = defineAsyncComponent(() => import('~/components/home/HeroCarousel/HeroCarousel.vue'));
    const FeaturedProducts = defineAsyncComponent(() => import('~/components/home/FeaturedProducts/FeaturedProducts.server.vue'));
    const FeaturedPartnerBadge = defineAsyncComponent(() => import('~/components/home/FeaturedPartnerBadge/FeaturedPartnerBadge.vue'));
    const NewsletterSignup = defineAsyncComponent(() => import('~/components/NewsletterSignup/NewsletterSignup.vue'));
    const VideoDialog = defineAsyncComponent(() => import('~/components/video/VideoDialog/VideoDialog.vue'));

    definePageMeta({
        scrollToTop: true
    });

    const $config = useRuntimeConfig();
    const { bannerState, toggleBanner } = useGlobalPageBanner();
    const { getHeaderHeightPx } = useUiState();
    const useCdnResourceObj = useCdnResource();
    const { getResourceCdnUrl } = useCdnResourceObj;
    const brandTitle = $config.public.brand.title;

    const { staticIsMobile } = useBrowserInfo();
    const featItemAspectRatio = ref(322 / 212);

    const mobileHeightValue = getMobileHeightValue();
    const mobileHeightOffset =  getHeaderHeightPx(staticIsMobile.value, bannerState.value);
    const carouselMobileHeight = ref(staticIsMobile.value ? `calc(${mobileHeightValue} - ${mobileHeightOffset}px)` : '80vh');


    useHead({
        title: `StringKing · Lacrosse Sticks, Baseball Bats, & Custom Shirts | ${brandTitle}`,
        meta: [
            {
                hid: 'description',
                name: 'description',
                content: 'Lacrosse sticks, baseball & softball bats, custom-fit shirts, and hockey sticks available for sale online! Shop the best in lax, baseball, softball, and more!'
            }
        ]
    });
</script>

<style lang="scss">
.page-index {
    .featured-items {
        article:nth-child(odd) {
            backdrop-filter: contrast(0.92);
            @supports not (backdrop-filter: contrast(0.92)) {
                background-color: #f5f5f5;
            }
        }
    }

    .newsletter-box {
        min-height: 8rem;
    }

    .card-play-video {
        position: relative;

        &-icon {
            position: absolute !important;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            filter: invert(1);
        }
    }
}
</style>
